import { AutoMode, Brush, Code, ColorLens, Devices, EmojiNature, Facebook, GitHub, Gradient, Home, Icecream, Instagram, LinkedIn, Palette, PieChart, SettingsInputSvideo, Tablet } from "@mui/icons-material";
import { instaLink, linkedinLink, title } from "../Config/Config";
import html__svg from '../Assets/icon/html.svg'
import css__svg from '../Assets/icon/css.svg'
import bootstrap__svg from '../Assets/icon/bootstrap.svg'
import sass__svg from '../Assets/icon/sass.svg'
import tailwind__svg from '../Assets/icon/tailwind.svg'
import javascript__svg from '../Assets/icon/javascript.svg'
import react__svg from '../Assets/icon/react.svg'
import npmjs__svg from '../Assets/icon/npmjs.svg'
import threejs__svg from '../Assets/icon/threejs.svg'
import photoshop__svg from '../Assets/icon/photoshop.svg'
import figma__svg from '../Assets/icon/figma.svg'
import spline__svg from '../Assets/icon/spline.svg'
import webflow__svg from '../Assets/icon/webflow.svg'
import github__svg from '../Assets/icon/github.svg'
import chatgpt__svg from '../Assets/icon/chatgpt.svg'
import canvas__svg from '../Assets/icon/canvas.svg'


// todo ---- Header Content
// todo ---- Header content and menu items


export const HeaderItem = [
     { path: '/code', label: 'Code', inout: true },
     { path: '/about', label: 'About', },
     { path: '/websites', label: 'Websites' },
     { path: '/contact-us', label: 'Contact us' },
     { path: '#', label: 'Tools', exLink: true },
     { path: 'https://www.buymeacoffee.com/thedc.tech', label: '☕ Buy me a coffee', button: true },
];

export const HeaderItemtools = [
     { path: '/colors', label: 'Explore Vibrant Color ', icon: <SettingsInputSvideo /> },
     { path: '/gradients', label: 'Explore Vibrant Gradients', icon: <ColorLens /> },
     // { path: 'https://thedcux.github.io/tools/#gradient-generate', label: 'Gradient Generate', icon: <Gradient /> },
     { path: 'https://thedcux.github.io/tools/#svg-to-code', label: 'Svg to Code', icon: <SettingsInputSvideo /> },
     { path: 'https://thedcux.github.io/tools/#svg-circle-progress', label: 'SVG Circle Progress', icon: <AutoMode /> },
     { path: 'https://thedcux.github.io/tools/#responsive-tester', label: 'Responsive Design Tester', icon: <Devices /> },
];

// todo ---- Bar Menu Content

export const BarMenu = [
     {
          name: 'home',
          icon: <Home />,
          link: '/'
     },
     {
          name: 'About',
          icon: <Home />,
          link: '/about'
     },
     {
          name: 'Instagram',
          icon: <Home />,
          link: instaLink
     },
     {
          name: 'Websites',
          icon: <Home />,
          link: '/websites'
     },
     {
          name: 'Tools',
          icon: <Home />,
          link: '/tools',
          btn: true
     },
];



export const socialMedia = [
     {
          name: 'instagram',
          icon: <Instagram />,
          link: instaLink,
     },
     {
          name: 'facebook',
          icon: <Facebook />,
          link: "https://www.facebook.com/",
     },

     {
          name: 'Linkedin',
          icon: <LinkedIn />,
          link: linkedinLink,
     },
     {
          name: 'snapchat',
          icon: <Icecream />,
          link: "https://snapchat.com/t/RzcXS8BN",
     },
     {
          name: 'Github',
          icon: <GitHub />,
          link: "https://github.com/thedchere",
     },
     // {
     //      name: 'Codepen',
     //      icon: <Code />,
     //      link: "https://codepen.io/thedc__",
     // },
]




//todo Footer Content and Medias With Name and Url

export const footerData = {

     text: {
          heading: `Do you want to start a project together?`,
          paragraph: "We create design and brands that help companies grow.",
          copyright: `Copyright © ${new Date().getFullYear()} ${title}. All Rights Reserved.`,
     },

     socialMedia: [
          {
               name: 'facebook',
               icon: <Facebook />,
               link: "https://www.facebook.com/",
          },
          {
               name: 'Codepen',
               icon: <Code />,
               link: "https://codepen.io/thedc__",
          },
          {
               name: 'Linkedin',
               icon: <LinkedIn />,
               link: "https://www.linkedin.com/in/devsunilsharma?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
          },
          {
               name: 'instagram',
               icon: <Instagram />,
               link: "https://instagram.com/thedc__",
          }
     ]
};

//todo ---- About page content
//todo ---- about page all about me and my skills and tools

export const skill = [
     {
          name: 'HTML5',
          Icon: html__svg,
          link: 'https://html5.org/'
     },
     {
          name: 'CSS3',
          Icon: css__svg,
          link: 'https://css3.com/'

     },
     {
          name: 'Bootstrap',
          Icon: bootstrap__svg,
          link: 'https://getbootstrap.com/'
     },
     {
          name: 'SCSS',
          Icon: sass__svg,
          link: 'https://sass-lang.com/'
     },
     {
          name: 'tailwind',
          Icon: tailwind__svg,
          link: 'https://tailwindcss.com/'
     },
     {
          name: 'Javascript',
          Icon: javascript__svg,
          link: 'https://www.javascript.com/'
     },
     {
          name: 'React js',
          Icon: react__svg,
          link: 'https://react.dev/'
     },
     {
          name: 'NPM',
          Icon: npmjs__svg,
          link: 'https://www.npmjs.com/'
     },
     {
          name: 'React Three Fiber',
          Icon: threejs__svg,
          link: 'https://r3f.docs.pmnd.rs/'
     },
     {
          name: 'canvas',
          Icon: canvas__svg,
          link: 'https://www.canva.com/'
     },
     {
          name: 'Photoshop',
          Icon: photoshop__svg,
          link: 'https://www.photopea.com/'
     },
     {
          name: 'Figma',
          Icon: figma__svg,
          link: 'https://www.figma.com/'
     },
     {
          name: 'Spline',
          Icon: spline__svg,
          link: 'https://spline.design/'
     },
     {
          name: 'Webflow',
          Icon: webflow__svg,
          link: 'https://webflow.com/'
     },
     {
          name: 'Github',
          Icon: github__svg,
          link: 'https://github.com'
     },
     {
          name: 'chat-gpt',
          Icon: chatgpt__svg,
          link: 'https://chatgpt.com/'
     },
]


export const TopItem = [
     {
          link: '/gradients',
          name: 'Color Gradients',
          pragraph: 'Explore and create stunning color gradients.',
          icon: <Gradient />,
          viewApp: 'View on the App Store',
     },
     {
          link: '/colors',
          name: 'Color Palettes',
          pragraph: 'Browse thousands of curated color palettes.',
          icon: <Brush />,
          viewApp: 'View on the Play Store',
     },
     {
          link: '/aicolor',
          name: 'AI Generated Colors',
          pragraph: 'Discover AI-powered unique color combinations.',
          icon: <EmojiNature />,
          viewApp: 'View on the Play Store',
     },
     {
          link: '/unique-color',
          name: 'Unique Color Shades',
          pragraph: 'Generate and explore different shades of unique colors.',
          icon: <Palette />,
          viewApp: 'View on the Play Store',
     },
     {
          link: 'https://thedcux.github.io/tools/#svg-circle-progress',
          name: 'SVG Progress Generator',
          pragraph: 'Easily create animated SVG progress circles.',
          icon: <PieChart />,
          viewApp: 'Try the Tool',
     },
     {
          link: 'https://thedchere.github.io/tools/#svg-to-code',
          name: 'SVG to Code',
          pragraph: 'Convert SVG files into clean, optimized code effortlessly.',
          icon: <Code />,
          viewApp: 'Check out my Website',
     },
     {
          link: 'https://thedcux.github.io/tools/#responsive-tester',
          name: 'Responsive Design Tester',
          pragraph: 'Test website responsiveness across different screen sizes.',
          icon: <Tablet />,
          viewApp: 'Check out my Website',
     },
];