import React from 'react';
import { Parallax, ParallaxProvider } from 'react-scroll-parallax';
import { codeInfomation } from '../../AllContent/CodeData';
import { VpnLock } from '@mui/icons-material';
import Loader from '../../Config/Loader';
import { useNavigate } from 'react-router-dom';

const CodeInfo = () => {

      const getRandomCardClass = () => {
            const classes = ['qw-1', 'qw-2', 'qw-3'];
            return classes[Math.floor(Math.random() * classes.length)];
      };




      const handleRemove = () => {
            localStorage.clear();
            window.location.reload();
      };


      const navigate = useNavigate();

      const handleClick = (item:any) => {
            navigate('/codes', { state: { item } });
            
        };


      return (
            <>

                  <Loader timer={1000} />

                  <section className='ai'>
                        <ParallaxProvider>
                              <Parallax speed={5}>
                                    <div className='__headings'>
                                          <div className='__container'>
                                                <div className='row pt-3'>
                                                      <div className='col-md-6'>
                                                            <div className='__left'>
                                                                  <button type='button' onClick={handleRemove} className='btn copt-btn'> <VpnLock /> </button>
                                                                  <h2> Explore Your Favorite Code and Idea</h2>
                                                                  <h4>Crafting Unique Experiences with HTML, CSS, SCSS, Tailwind css, Bootsrap,  React js and typescript , My Idea Or ChatGPT </h4>
                                                            </div>
                                                      </div>
                                                </div>
                                          </div>
                                    </div>
                              </Parallax>

                              <div className='__container'>
                                    <div className='__sec'>
                                          <div className='__cards'>
                                                <div className='row'>
                                                      {codeInfomation.map((item:any, index) => (
                                                            <div className='col-xl-3 col-lg-4 col-md-6' key={index}>
                                                                  <Parallax speed={index % 2 === 0 ? 0 : 8}>
                                                                        <div className={`card-qw ${getRandomCardClass()}`} onClick={() => handleClick(item)} style={{ cursor: 'pointer' }}>
                                                                              <div className='card-header'>
                                                                                    <img src={item.imageUrl} alt={item.name} />
                                                                                    <h6>{item.language}</h6>
                                                                              </div>
                                                                              <div className='card-body'>
                                                                                   
                                                                                    <h5>{item.name}</h5>
                                                                                    <p>{item.description}</p>
                                                                              </div>
                                                                        </div>
                                                                  </Parallax>
                                                            </div>
                                                      ))}
                                                </div>
                                          </div>
                                    </div>
                              </div>
                        </ParallaxProvider>
                  </section>


            </>
      );
}

export default CodeInfo;
