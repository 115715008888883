import React, { Suspense } from 'react';
import { Link } from 'react-router-dom';
import { Instagram, LinkedIn } from '@mui/icons-material';
import { instaLink, linkedinLink, sendMail } from '../../Config/Config';
const CanvaSection = React.lazy(() => import('./CanvaSection'));


const Banner = () => {

     return (
          <>

               <section className='banner'>
                    <div className='__wapper'>
                         <div className='row'>

                              {/* Text Content */}
                              <div className='col-lg-6'>
                                   <div className='__txt'>
                                        <div>
                                             <h1>
                                                  Design... Develop... <span className="color-txt">Deliver...</span>
                                             </h1>
                                        </div>
                                        <p>Turning ideas into digital experiences that inspire and deliver results. Let’s create something extraordinary!</p>

                                        
                                        <div className='__links'>
                                             <button type='button' className='btn' onClick={sendMail} aria-label="Contact via Gmail">Contact via Gmail</button>
                                             <Link to={instaLink} className='--insta' aria-label="follow in instagram"><Instagram/></Link>
                                             <Link to={linkedinLink} className='--insta' aria-label="follow in linkedin"><LinkedIn/></Link>
                                        </div>
                                   </div>
                              </div>

                              {/* Canva Section */}
                              <div className='col-lg-6'>
                                   <div className='__canva' aria-label="Creative canvas section">
                                        <Suspense fallback={"Loading....."}>
                                             <CanvaSection />
                                        </Suspense>
                                   </div>
                              </div>
                         </div>
                    </div>
               </section>
          </>
     );
}

export default Banner;
