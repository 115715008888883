import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import __img_india from '../../Assets/Image/india.webp';
import __img_working from '../../Assets/Image/working.webp';
import { skill, TopItem } from '../../AllContent/Content';
import { OpenInNew } from '@mui/icons-material';

const About = (props: any) => {
     const headingRef = useRef<HTMLDivElement>(null);

     const rotateToMouse = (e: MouseEvent, imgElement: HTMLImageElement) => {
          const bounds = imgElement.getBoundingClientRect();
          const mouseX = e.clientX;
          const mouseY = e.clientY;
          const leftX = mouseX - bounds.x;
          const topY = mouseY - bounds.y;
          const center = {
               x: leftX - bounds.width / 2,
               y: topY - bounds.height / 2,
          };
          const distance = Math.sqrt(center.x ** 2 + center.y ** 2);

          imgElement.style.transform = `
      scale3d(4, 4, 4)
      rotate3d(
        ${center.y / 100},
        ${center.x / 100},
        0,
        ${Math.log(distance) * 4}deg
      )
    `;
          imgElement.style.filter = 'brightness(1.2) contrast(1.1)';
     };

     const resetImage = (imgElement: HTMLImageElement) => {
          imgElement.style.transform = '';
          imgElement.style.filter = '';
     };

     useEffect(() => {
          if (!headingRef.current) return;

          const images = headingRef.current.querySelectorAll('img');
          images.forEach((img) => {
               const handleMouseMove = (e: MouseEvent) => rotateToMouse(e, img as HTMLImageElement);
               const handleMouseLeave = () => resetImage(img as HTMLImageElement);

               img.addEventListener('mousemove', handleMouseMove);
               img.addEventListener('mouseleave', handleMouseLeave);

               return () => {
                    img.removeEventListener('mousemove', handleMouseMove);
                    img.removeEventListener('mouseleave', handleMouseLeave);
               };
          });
     }, []);



     return (
          <section className="__abouts">
               <div className="__wapper">
                    <div className="__about-heading" ref={headingRef}>
                         I’m Sunil Sharma, <br />
                         indian <img src={__img_india} alt="my-picture" /> web designer working  on <Link to="https://www.gnisoftsolutions.com/" target='_blank' >gni soft
                              solutions</Link> <img src={__img_working} alt="my-picture" /> or <Link to="https://webflow.com/" target='_blank'>Webflow experiences</Link>
                    </div>


                    <div className='__skill'>
                         <div className='row'>
                              {skill.map((val, index) => (
                                   <div className='col-md-2 col-2' key={index}>
                                        <div className='__item' >
                                             <Link to={val.link} target='_blank' className='stretched-link'>
                                                  <div className='__icon'>
                                                       <img src={val.Icon} alt={val.name} />
                                                  </div>
                                             </Link>
                                        </div>
                                   </div>
                              ))}
                         </div>
                    </div>


                    <div className='__Lists'>
                         <div className='row justify-content-center'>
                              {
                                   TopItem.map((val, index) => (
                                        <div className='col-md-3 col-6' key={index}>
                                             <div className='__item'>
                                                  <Link to={val.link}>
                                                       <div className='__icon'>
                                                            {val.icon}
                                                       </div>

                                                       <div className='__txt'>
                                                            <h5 className='color-txt'> {val.name}</h5>
                                                            <p> {val.pragraph}</p>
                                                       </div>

                                                       <div className='__hoverItem'>
                                                            <div className='__hovertxt'>
                                                                 {val.icon} <br />
                                                                 <p>{val.viewApp}</p>
                                                            </div>
                                                       </div>

                                                       <p className='__viewApp'><OpenInNew/></p>
                                                  </Link>
                                             </div>
                                        </div>
                                   ))
                              }
                         </div>

                    </div>


               </div>
          </section>
     );
};

export default About;
