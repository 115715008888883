import React, { useEffect } from 'react';
import { instaLink, linkedinLink, logo, sendMail } from '../Config/Config';
import { Link, useLocation } from 'react-router-dom';
import { footerData, socialMedia } from '../AllContent/Content';
import { Assignment, Book, Code, ColorLens, FiberSmartRecord, GradientSharp, Home, Language, ManageAccounts, PhotoSizeSelectSmall } from '@mui/icons-material';



const Footer = () => {

     // useEffect(() => {
     //      const disableRightClick = (event: MouseEvent) => {
     //           event.preventDefault();
     //      };

     //      document.addEventListener('contextmenu', disableRightClick);


     //      return () => {
     //           document.removeEventListener('contextmenu', disableRightClick);
     //      };
     // }, []);

     const location = useLocation();

     useEffect(() => {
          window.scrollTo({
               top: 0,
               behavior: "smooth", // Smooth scrolling effect
          });
     }, [location.pathname]); // Runs every time the route changes


const MenuItem = [
     {
          name: 'Home',
          link: '/',
          icon: <Home />,
     },
     {
          name: 'About',
          link: '/about',
          icon: <ManageAccounts />,
     },
     {
          name: 'Websites',
          link: '/websites',
          icon: <Language />,
     },
     {
          name: 'License',
          link: '/license',
          icon: <Assignment />,
     },
     {
          name: 'Blog',
          link: '/blog',
          icon: <Book />,
     },
];

const ToolItem = [
     {
          name: 'Color',
          link: '/colors',
          icon: <ColorLens />,
     },
     {
          name: 'gradients',
          link: '/gradients',
          icon: <GradientSharp />,
     },
     {
          name: 'SVG to code',
          link: 'https://thedchere.github.io/tools/#svg-to-code',
          icon: <Code />,
     },

     {
          name: 'SVG circle',
          link: 'https://thedchere.github.io/tools/#svg-circle-progress',
          icon: <FiberSmartRecord />,
     },
     {
          name: 'responsive tester',
          link: 'https://thedchere.github.io/tools/#responsive-tester',
          icon: <PhotoSizeSelectSmall />,
     },

];


return (
     <footer>

          <div className='__container'>
               <div className='__dc-footer'>
                    <div className='row'>
                         <div className='col-12'>
                              <div className='__txt'>
                                   <Link to="/" className='logo'>
                                        <img src={logo} alt='logo' />
                                   </Link>
                                   <h5>{footerData.text.heading}</h5>
                                   <p> {footerData.text.paragraph}</p>


                                   <button className='btn' onClick={sendMail}>
                                        Contact via Gmail
                                   </button>
                              </div>
                         </div>

                         <div className='col-md-4'>
                              <div className='__list'>
                                   <ul>
                                        {MenuItem.map((item, index) => (
                                             <li key={index}>
                                                  <Link to={item.link}>
                                                       <span> {item.icon}</span>
                                                       <p>{item.name}</p>
                                                  </Link>
                                             </li>
                                        ))}
                                   </ul>
                              </div>
                         </div>

                         <div className='col-md-4'>
                              <div className='__list'>
                                   <ul>
                                        {ToolItem.map((item, index) => (
                                             <li key={index}>
                                                  <Link to={item.link}>
                                                       <span> {item.icon}</span>
                                                       <p>{item.name}</p>
                                                  </Link>
                                             </li>
                                        ))}
                                   </ul>
                              </div>
                         </div>

                         <div className='col-md-4'>
                              <div className='__list'>
                                   <ul>
                                        {socialMedia.map((item, index) => (
                                             <li key={index}>
                                                  <Link to={item.link} target='_blank'>
                                                       <span> {item.icon}</span>
                                                       <p>{item.name}</p>
                                                  </Link>
                                             </li>
                                        ))}
                                   </ul>
                              </div>
                         </div>
                    </div>
               </div>

               <div className='copy-right'>
                    <p> {footerData.text.copyright} </p>
                    <p> Created by <Link to={linkedinLink} title='linkdin' target='_blank'>@sunilsharma</Link> or <Link title='Instagram' to={instaLink} target='_blank'>@tᖾᥱᑯᥴ_</Link></p>
               </div>
          </div>
     </footer>
);
}

export default Footer;
