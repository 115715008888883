import React, { useEffect, useState } from 'react';

const Demouij2 = () => {

    const [colors, setColors] = useState<string[]>([]);

    const fetchColors = async () => {
        const jsonData = {
            mode: "transformer",
            num_colors: 4,
            temperature: "1.2",
            num_results: 50,
            adjacency: ["0", "65", "45", "35", "65", "0", "35", "65", "45", "35", "0", "35", "35", "65", "35", "0"],
            palette: ["#ffffff", "-", "-", "-"],
        };

        try {
            const response = await fetch("https://api.huemint.com/color", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(jsonData),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();
            console.log("API Response:", data);

            // Assuming the API returns an array of color palettes
            if (data.results && data.results.length > 0) {
                setColors(data.results[0].palette);
            }
        } catch (error) {
            console.error("Error fetching colors:", error);
        }
    };

    useEffect(() => {
        fetchColors();
    }, []);

    return (
        <div style={{marginTop: '100px'}}>
            <h2>Generated Color Palette</h2>
            <div style={{ display: "flex", gap: "10px", marginTop: "10px" }}>
                {colors.map((color:any, index:any) => (
                    <div
                        key={index}
                        style={{
                            width: "50px",
                            height: "50px",
                            backgroundColor: color,
                            border: "1px solid #ccc",
                        }}
                    ></div>
                ))}
            </div>
        </div>
    );
}

export default Demouij2;
