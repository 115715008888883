import React, { useEffect, useState } from 'react';
import { title } from '../../Config/Config';
import AllColor from './AllColor';


type ColorType = {
    id: number;
    hex1: string;
    hex2: string;
    hex3: string;
    hex4: string;
};


const AiColor = () => {

    const [colors, setColors] = useState<any>([]);

    const generateColors = (count: number): ColorType[] => {
        const colors: ColorType[] = [];
        for (let i = 0; i < count; i++) {
            colors.push({
                id: i + 1,
                hex1: `#${Math.floor(Math.random() * 16777215).toString(16).padStart(6, "0")}`,
                hex2: `#${Math.floor(Math.random() * 16777215).toString(16).padStart(6, "0")}`,
                hex3: `#${Math.floor(Math.random() * 16777215).toString(16).padStart(6, "0")}`,
                hex4: `#${Math.floor(Math.random() * 16777215).toString(16).padStart(6, "0")}`
            });
        }
        return colors;
    };

    useEffect(() => {
        setColors(generateColors(10000));
    }, []);



    return (
        <div className={`__colors-556 ${colors.length !== '0' ? '' : '---0-data'}`}>

            <div className='__head'>
                <div className='__container'>
                    <h2> Dynamic {colors.length}+ Color Library & Shades</h2>
                    <p>
                        {title} Dynamic Color Library & Shades brings you an ever-evolving collection of colors.
                        Explore randomly generated shades, curated palettes, and variations of specific colors like red, blue, and more.
                        Perfect for designers, developers, and artists seeking fresh color inspiration.
                    </p>

                    <div className='__svg'>
                        <svg fill="#000000" version="1.1" id="Icons" viewBox="0 0 32 32" ><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <path d="M5.7,23.3c-0.4-0.4-1-0.4-1.4,0C3.8,23.8,2,25.6,2,27c0,1.7,1.3,3,3,3s3-1.3,3-3C8,25.6,6.2,23.8,5.7,23.3z"></path> <path d="M28.8,15.3l-3-3.4c-1.5,2-3.2,3.9-4.7,5.3c0,0-0.1,0.1-0.1,0.1c-0.2,1.5-1.4,2.6-3,2.6c-1.7,0-3-1.3-3-3s1.3-3,3-3 c0.4,0,0.9,0.1,1.2,0.3c-0.2,0.2-0.4,0.4-0.6,0.6c-0.4,0.4-0.4,1,0,1.4c0.2,0.2,0.4,0.3,0.7,0.3c0.3,0,0.5-0.1,0.7-0.3 c4-4,10.1-11.5,7.6-14c-1.7-1.7-5.5,0.5-8.5,2.9c-1.4-0.5-3-0.2-4.1,0.9l-4,4c-2.1,2.1-4.5,3.8-7.3,5c-1,0.4-1.7,1.3-1.9,2.4 s0.1,2.2,0.9,2.9c0.6,0.6,1.4,1,2.3,1c0.8,0,1.5-0.3,2.1-0.8l4.5-4.5c0.4-0.4,1-0.4,1.4,0c0.4,0.4,0.4,1,0,1.4l-3.9,3.9l7.1,6.3 c0.9,0.8,2,1.2,3.1,1.2c1.2,0,2.4-0.5,3.4-1.4l5.6-5.6C30.3,20,30.4,17.2,28.8,15.3z M26.3,3.7c0.3,0.5-0.6,2.7-2.8,5.7l-2.6-2.9 C24.5,3.8,26.1,3.6,26.3,3.7z"></path> </g> </g></svg>
                        <svg fill="#000000" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M12.022,23a11.053,11.053,0,0,0,10.921-9.5,5.853,5.853,0,0,0-.577-3.5c-1.655-3.146-4.777-2.671-7.056-2.322-1.18.178-2.4.366-2.865-.035A2.416,2.416,0,0,1,12.02,6c0-2.683,0-5-3-5C3.753,1,1,6.534,1,12A11.023,11.023,0,0,0,12.022,23ZM9.016,3c.909,0,1,0,1,3a3.941,3.941,0,0,0,1.122,3.168c1.163,1,2.844.741,4.469.494,2.483-.379,4.061-.482,4.986,1.276a3.844,3.844,0,0,1,.363,2.293A9.024,9.024,0,0,1,3,12C3,8.382,4.6,3,9.016,3ZM5,7.5A1.5,1.5,0,1,1,6.5,9,1.5,1.5,0,0,1,5,7.5ZM4,12a1.5,1.5,0,1,1,1.5,1.5A1.5,1.5,0,0,1,4,12Zm3.5,3A1.5,1.5,0,1,1,6,16.5,1.5,1.5,0,0,1,7.5,15Zm8,3A3.5,3.5,0,1,0,12,14.5,3.5,3.5,0,0,0,15.5,18Zm0-5A1.5,1.5,0,1,1,14,14.5,1.5,1.5,0,0,1,15.5,13Z"></path></g></svg>
                        <svg fill="#000000" viewBox="0 0 96 96" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><title></title><path d="M90,24.0217a17.9806,17.9806,0,0,0-5.2969-12.7968,18.5331,18.5331,0,0,0-25.6054,0L46.23,24.0972,41.9121,19.78a5.9994,5.9994,0,1,0-8.4844,8.4844l4.3184,4.3184L7.7578,62.5647A5.9956,5.9956,0,0,0,6,66.8069V83.9221a5.9966,5.9966,0,0,0,6,6H29.1152a5.9956,5.9956,0,0,0,4.2422-1.7578L63.34,58.176l4.3184,4.3184A5.9994,5.9994,0,0,0,76.1426,54.01L71.825,49.6924,84.6973,36.8245A17.9861,17.9861,0,0,0,90,24.0217Zm-63.3691,53.9H18V69.2913L46.2305,41.0667l8.625,8.625Z"></path></g></svg>
                        <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" fill="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M10.5 10.5c.002 2.762-2.237 5-5 5s-5.002-2.238-5-5c-.002-2.76 2.237-5 5-5s5.002 2.24 5 5z" color="#000000" fill="#ff15a1" stroke="#373737" stroke-width=".49999682600000006"></path><path d="M8 1.401a4.998 4.998 0 0 0-2.488 9.334c-.004-.078-.012-.155-.012-.234a4.998 4.998 0 0 1 7.488-4.334A4.994 4.994 0 0 0 8 1.4z" fill="#1583ff"></path><path d="M10.5 5.5a4.998 4.998 0 0 0-5 5c0 .08.008.157.012.235A4.998 4.998 0 0 0 13 6.401c0-.079-.008-.156-.012-.234A4.975 4.975 0 0 0 10.5 5.5z" fill="#00cf2d"></path><path d="M12.988 6.167c.004.078.012.155.012.234a4.998 4.998 0 0 1-7.489 4.334 4.994 4.994 0 0 0 4.989 4.766 4.998 4.998 0 0 0 2.488-9.334z" fill="#f8ff15"></path><path d="M5.512 10.735a4.996 4.996 0 0 0 2.486 4.093 4.987 4.987 0 0 0 2.49-4.091A4.978 4.978 0 0 1 8 11.4a4.975 4.975 0 0 1-2.488-.666z" fill="#ef0000"></path><path d="M7.998 6.173A4.991 4.991 0 0 0 5.5 10.5c0 .079.008.156.012.234a4.978 4.978 0 0 0 4.977.002c.003-.079.011-.157.011-.236a4.99 4.99 0 0 0-2.502-4.328z" fill="#383027"></path><path d="M5.5 5.5c-.91 0-1.76.247-2.494.67a4.99 4.99 0 0 0 2.506 4.564c-.004-.077-.012-.154-.012-.233a4.991 4.991 0 0 1 2.498-4.328A4.975 4.975 0 0 0 5.5 5.5z" fill="#5100cc"></path><path d="M8 1.401a4.998 4.998 0 0 0-4.994 4.77 4.998 4.998 0 1 0 4.992 8.658 4.998 4.998 0 1 0 4.99-8.662A4.994 4.994 0 0 0 8 1.4z" fill="none" stroke="#373737" stroke-width=".9999936520000001"></path></g></svg>
                    </div>
                </div>
            </div>

            <div className='__color-data'>
                <div className='__container'>
                        <AllColor colors={colors} />
                </div>
            </div>

        </div>
    );
}

export default AiColor;
