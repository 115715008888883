import React from 'react';
import { sendMail } from '../Config/Config';

const License = () => {
    return (
        <section className='__license p-2'>
            <div className="license-container">
                <h1 className="license-title color-txt">License</h1>
                <p className="license-summary">
                    Welcome to <strong>thedc.tech</strong>. Here’s a clear and straightforward license agreement for all our tools and resources.
                </p>

                <div className="license-section">
                    <h2 className="section-title color-txt">What You Can Do</h2>
                    <ul className="license-list">
                        <li>Use our tools like SVG to Code, SVG Progress Circle, and Gradient Color tools in your personal or commercial projects.</li>
                        <li>Leverage resources like Responsive Tester and React code examples to improve your designs and development workflows.</li>
                        <li>No need to give credit, but a mention of <strong>thedc.tech</strong> is always appreciated!</li>
                    </ul>
                </div>

                <div className="license-section">
                    <h2 className="section-title color-txt">What You Cannot Do</h2>
                    <ul className="license-list">
                        <li>Sell or distribute our tools as standalone products or part of any platform.</li>
                        <li>Use tools for creating templates or applications intended for resale.</li>
                        <li>Copy, reverse-engineer, or use our tools for machine learning or AI training with permission.</li>
                        <li>Engage in unlawful activities using our tools or resources.</li>
                    </ul>
                </div>

                <p className="license-note">
                    For any specific permissions or questions, feel free to contact us at <button type='button' onClick={sendMail} className='btn color-txt p-0'>Email Now</button>.
                </p>

                <p className="license-date">Updated on 23 Dec 2024</p>
            </div>
        </section>
    );
};

export default License;
