import React, { Suspense } from 'react';
import toast from 'react-hot-toast';

const AllColor = (props: any) => {

    const handleCopy = (hex: string) => {
        navigator.clipboard.writeText(hex);
        toast.success(`Copied: ${hex.toUpperCase()}`);
    };
    

    return (
        <div className='__colors'>
            <Suspense fallback={"Loading....."}>
                <div className='row row-gap-4'>
                    {props?.colors.map((color: any, index: number) => (
                        <div className='col-lg-3 col-sm-4 col-6' key={index}>
                            <div className='__color-card-group'>
                                {[color.hex1, color.hex2, color.hex3, color.hex4].map((hex, i) => (
                                    <div key={i}  onClick={() => handleCopy(hex)} className='__color-card' style={{ backgroundColor: hex }}>
                                        <div className='__color-hex'>{hex.toUpperCase()}</div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </Suspense>

        </div>
    );
}

export default AllColor;