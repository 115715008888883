import { Link } from "react-router-dom";

const BuyMeACoffee = () => {
     return (
          <Link
               to="https://www.buymeacoffee.com/thedc.tech"
               target="_blank"
               rel="noopener noreferrer"
               className="btn-toggle">
               ☕ Buy me a coffee
          </Link>
     );
};

export default BuyMeACoffee;
