import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Parallax, ParallaxProvider } from 'react-scroll-parallax';
import Loader from '../../Config/Loader';
import axios from 'axios';
import { SpaceDashboard } from '@mui/icons-material';



const shuffleArray = (array: any[]) => {
     return array
          .map((item) => ({ item, sort: Math.random() }))
          .sort((a, b) => a.sort - b.sort)
          .map(({ item }) => item);
};

const HelpAi = () => {
     const apiwebsite = 'https://dummyjson.com/c/ff31-60d9-4375-8011';

     const [data, setData] = useState([]);

     const updateWeb = async () => {

          let res = await axios.get(apiwebsite);

          if (res?.statusText === "OK") {
               console.log(res)
               setData(res?.data)

          } else {

          }
     }

     useEffect(() => {
          updateWeb()
     }, [])


     const [searchQuery, setSearchQuery] = useState<string>('');

     const filteredWebsites = data.filter((site: any) =>
          site.name.toLowerCase().includes(searchQuery.toLowerCase())
     );

     const shuffledWebsites = shuffleArray(filteredWebsites);

     const noResults = filteredWebsites.length === 0 && shuffledWebsites.length === 0;

    

     return (
          <>
               <Loader timer={2000} />

               <section className='ai'>
                    <ParallaxProvider>
                         <Parallax speed={5}>
                              <div className='__headings'>
                                   <div className='__container pt-3'>
                                        <div className='row align-items-center'>
                                             <div className='col-md-6'>
                                                  <div className='__left'>
                                                       <button type='button' className='btn copt-btn'> <SpaceDashboard /> </button>
                                                       <h2>Explore Your Favorite {data.length}+ Websites</h2>
                                                       <h4>Search by URL, name, category, or type of work to discover the right site or project in an instant.</h4>
                                                  </div>
                                             </div>

                                             <div className='col-md-6'>
                                                  <div className='__select'>
                                                       <input
                                                            type='search'
                                                            placeholder='Search Websites'
                                                            value={searchQuery}
                                                            onChange={(e) => setSearchQuery(e.target.value)} />
                                                  </div>
                                             </div>
                                        </div>


                                   </div>
                              </div>
                         </Parallax>

                         <div className='__container'>
                              <div className='__sec'>
                                   <div className='__cards'>
                                        <TransitionGroup className='row'>
                                             {!noResults ? (
                                                  shuffledWebsites.map((item: any, index) => (
                                                       <CSSTransition key={index} timeout={500} classNames='fade'>
                                                            <div className='col-xl-3 col-lg-4 col-md-6' >
                                                                 <Parallax speed={index % 2 === 0 ? 0 : 8} className='hne__ahn'>
                                                                      <div className='card-qw'>
                                                                           <Link
                                                                                to={item.url}
                                                                                className='stretched-link'
                                                                                target='_blank'
                                                                           ></Link>
                                                                           <div className='card-header'>
                                                                                <img src={item.imageUrl} alt={item.name + '-Logo'} />
                                                                           </div>
                                                                           <div className='card-body'>
                                                                                <h5>{item.name}</h5>
                                                                                <p>{item.description}</p>
                                                                           </div>
                                                                      </div>
                                                                 </Parallax>
                                                            </div>
                                                       </CSSTransition>
                                                  ))
                                             ) : (
                                                  <div className='col-12'>
                                                       <div className='__section-height'>
                                                            <p className='text-center'>No results found.</p>
                                                       </div>
                                                  </div>
                                             )}
                                        </TransitionGroup>

                                   </div>
                              </div>
                         </div>
                    </ParallaxProvider>
               </section>
          </>
     );
};

export default HelpAi;