import React from 'react';
import CodeInfo from './Ai/CodeInfo';
import Loader from '../Config/Loader';


const Coding = () => {
      
      return (
            <>
                  <Loader timer={1000} />

                  <CodeInfo />
            </>
      );
};

export default Coding;
