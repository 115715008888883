import React, { useState, useEffect } from 'react';
import MonacoEditor from '@monaco-editor/react';


const ReactSvgTransform = (svgCode: string) => {
     return svgCode
          .replace(/<\?xml version="1\.0" encoding="UTF-8"\?>/g, '')
          .replace(/<!--.*?-->/g, '')  // Remove all comments
          .replace(/<svg /g, '<Svg ')
          .replace(/<\/svg>/g, '</Svg>')
          .replace(/class=/g, 'className=')
          .replace(/style="([^"]+)"/g, (match, p1) => {
               const styles = p1.split(';').reduce((acc: any, style: string) => {
                    const [key, value] = style.split(':');
                    if (key && value) {
                         acc[key.trim()] = value.trim();
                    }
                    return acc;
               }, {});
               return `style={${JSON.stringify(styles)}}`;
          })
          .replace(/<path/g, '<Path')
          .replace(/<\/path>/g, '</Path>')
          .replace(/<g/g, '<G')
          .replace(/<\/g>/g, '</G>')
          .replace(/<title>.*?<\/title>/g, '')
          .replace(/<defs>.*?<\/defs>/g, '')
          .replace(/<desc>.*?<\/desc>/g, '')
          .replace(/<metadata>.*?<\/metadata>/g, '')
          .replace(/<foreignObject>.*?<\/foreignObject>/g, '')
          .replace(/<use.*?<\/use>/g, '')
          .replace(/<script>.*?<\/script>/g, '')
          .replace(/<animate.*?<\/animate>/g, '')
          .replace(/<set.*?<\/set>/g, '')
          .replace(/<discard.*?<\/discard>/g, '')
          .replace(/<mpath.*?<\/mpath>/g, '')
          .replace(/<switch.*?<\/switch>/g, '')
          .replace(/<filter.*?<\/filter>/g, '')
          .replace(/<fe.*?<\/fe.*?>/g, '')
          .replace(/<mask.*?<\/mask>/g, '')
          .replace(/<pattern.*?<\/pattern>/g, '')
          .replace(/<clipPath.*?<\/clipPath>/g, '')
          .replace(/<cursor.*?<\/cursor>/g, '')
          .replace(/<hatch.*?<\/hatch>/g, '')
          .replace(/<style.*?<\/style>/g, '')
          .replace(/<symbol.*?<\/symbol>/g, '')
          .replace(/<marker.*?<\/marker>/g, '');
};


const ReactSvg: React.FC = () => {
     const [svgCode, setSvgCode] = useState('');
     const [reactCode, setReactCode] = useState('');
     const [checkit, setCheckIt] = useState(false);

     const handleSvgChange = (value: string | undefined) => {
          setSvgCode(value || '');
     };

     useEffect(() => {
          const convertSvgManually = () => {
               try {
                    let result = svgCode;

                    // Remove unnecessary SVGRepo tags
                    // result = result.replace(/<g id="SVGRepo.*?>.*?<\/g>/, '');

                    // Convert the SVG to React code using the transform function
                    result = ReactSvgTransform(result);


                    setReactCode(result);
               } catch (error) {
                    setReactCode('Error converting SVG');
               }
          };

          convertSvgManually();
     }, [svgCode]);

     const copyToClipboard = (address: any, message: any) => {
          setCheckIt(true);
          var textField = document.createElement('textarea');
          textField.innerText = address;
          document.body.appendChild(textField);
          textField.select();
          document.execCommand('copy');
          textField.remove();
     };

     return (
          <div className='__out'>
               <div className='row'>
                    <div className='col-md-2'>
                         <div className='__settings column-sec'>
                              <p className='title'>Options</p>
                         </div>
                    </div>

                    <div className='col-md-10'>
                         <div className='jk-code'>
                              <div className='__svg-code column-sec'>
                                   <MonacoEditor
                                        height="200px"
                                        width="100%"
                                        language="xml"
                                        theme="vs-light"
                                        value={svgCode}
                                        onChange={handleSvgChange}
                                   />
                              </div>

                              <div className='__generate-code column-sec'>
                                   {reactCode && (
                                        <MonacoEditor
                                             height="60vh"
                                             language="javascript"
                                             theme="vs-light"
                                             line={1}
                                             value={reactCode}
                                        />
                                   )}

                                   <button className="copy-btn" onClick={() => { copyToClipboard(reactCode, 'SVG Code copied successfully!') }}>
                                        {checkit ?
                                             <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M4 14L9 19L20 8M6 8.88889L9.07692 12L16 5" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                                             :
                                             <svg viewBox="0 0 16.00 16.00" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M0 0H10V4H4V10H0V0Z" fill="#fff"></path> <path d="M16 6H6V16H16V6Z" fill="#fff"></path> </g></svg>
                                        }
                                   </button>
                              </div>
                         </div>
                    </div>
               </div>
          </div>
     );
};

export default ReactSvg;
