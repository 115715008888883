import React from 'react';
import MonacoEditor from '@monaco-editor/react';
import { Link, useLocation } from 'react-router-dom';
import { ArrowBack } from '@mui/icons-material';
import Loader from '../Config/Loader';

const Codes = () => {
     const location = useLocation();
     const { item } = location.state || { codeTsx: '', codescss: '' };

     return (
          <>
               <Loader timer={500} />

               <div className='__codes-app'>
                    <div className='__code-header'>
                         <Link className='btn back-btn' to="/code"> <ArrowBack /> </Link>
                         <div className='__txt'>
                              <p className='mb-0'> {item.name} </p>
                              <h6>{item.description}</h6>
                         </div>
                    </div>

                    <div className='code-sec'>
                         <div className='row'>
                              <div className={item.codescss ? 'col-md-7' : 'col-12'}>
                                   <div className='__settings column-sec'>
                                        <MonacoEditor
                                             height="100%"
                                             width="100%"
                                             language="typescript"
                                             theme="vs-dark"
                                             value={item.codeTsx}
                                             options={{ readOnly: true }}
                                        />
                                   </div>
                              </div>

                              {item.codescss &&
                                   <div className='col-md-5'>
                                        <div className='jk-code column-sec'>
                                             <MonacoEditor
                                                  height="100%"
                                                  width="100%"
                                                  language="scss"
                                                  theme="vs-dark"
                                                  value={item.codescss}
                                                  options={{ readOnly: true }}
                                             />
                                        </div>
                                   </div>

                              }


                         </div>
                    </div>
               </div>
          </>
     );
};

export default Codes;