import React from 'react';
import { logo, sendMail } from '../Config/Config';
import { Link } from 'react-router-dom';

// const toolHeaderLink = [
//      {
//           name: 'Tools',
//           link: '#'
//      }
// ]

const ToolHeader = () => {
     return (
          <>
               <div className='__tool-header'>
                    <div className='__container'>
                         <div className='__left'>
                              <Link to="/" className='__logo'>
                                   <img src={logo} alt='logo' />
                              </Link>

                              <ul>
                                   <li>
                                        <Link to="/"> Home </Link>
                                   </li>

                                   {/* {toolHeaderLink.map((link, index) => {
                                        return (
                                             <li key={index}>
                                                  <Link to={link.link}> {link.name} </Link>
                                             </li>
                                        )
                                   })} */}
                              </ul>


                         </div>

                         <button type='button' onClick={sendMail} className='btn'> Contact</button>
                    </div>
               </div>
          </>
     );
}

export default ToolHeader;
